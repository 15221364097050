.site-marketing-messaging-pause {
	@apply tw-relative tw-right-[24px] tw-z-10;

	@screen md {
		@apply tw--right-[24px];
	}
}

.site-marketing-messaging-pause-button {
	@apply tw-absolute tw-border tw-border-white tw-flex tw-items-center tw-h-[24px] tw-justify-center tw-left-1/2 tw-rounded-full tw-top-1/2 tw-transition-colors tw--translate-x-1/2 tw--translate-y-1/2 tw-w-[24px];

	&:before {
		@apply tw-border-white tw-content-[''];
	}

	&:focus, &:hover {
		@apply tw-bg-white;
	}
}

.site-marketing-messaging-pause-button-BANNER_BLACK {
	@apply tw-bg-gray;

	&:focus, &:hover {
		&:before {
			@apply tw-border-gray;
		}
	}
}

.site-marketing-messaging-pause-button-BANNER_RED {
	@apply tw-bg-red;

	&:focus, &:hover {
		&:before {
			@apply tw-border-red;
		}
	}
}

.site-marketing-messaging-pause-button-PAUSE {
	&:before {
		@apply tw-border-l-2 tw-border-r-2 tw-h-[10px] tw-w-[8px];
	}
}

.site-marketing-messaging-pause-button-PLAY {
	&:before {
		@apply tw-border-b-[6px] tw-border-l-[9px] tw-border-t-[6px] tw-translate-x-[1px];
		@apply tw-border-b-transparent tw-border-t-transparent #{!important};
	}
}
